.chatbox{
    border: 2px solid grey;
    border-radius: 5px;
    margin: 5px 0;
    padding: 10px;
    background-color: bisque;
}
.container{
    display: flex;
}
.container.me{
    flex-direction: row-reverse;
}
.me .chatbox{
    background-color: aqua;
}
.btm{
    position: fixed;
    width: 100%;
    bottom: 2px;
    display: flex;
    flex-direction: row;
}
.btm input{
    padding: 10px 0;
    flex-grow: 1;
}
.btm btoon{
    padding: 10px 0;
   
}